import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Column from "@components/Ui/Column";
import Icon from "@components/Ui/Icon";
import Clickable from "@components/Ui/Clickable";
import { useVendorContext } from "@contexts/vendor-context";
import NavItem from "@components/SideNav/NavItem";
import { useSesamyAuthTokenContext } from "@contexts/sesamy-auth-token-context";
import userHasScopes from "@components/VisibleForScopes/userHasScopes";
import { useRouter } from "next/router";
import Row from "@components/Ui/Row";
import navItems, { getActivePageIds } from "@utils/navigation";

const getNavStorage = () => {
  const navStorage = localStorage.getItem("navStorage");
  return navStorage ? JSON.parse(navStorage) : {};
};

export default () => {
  const navStorage = getNavStorage();
  const [expanded, setExpanded] = useState(
    navStorage.hasOwnProperty("expanded") ? navStorage.expanded : true,
  );
  const { pathname } = useRouter();
  const [activePageId, activeSubPageId] = getActivePageIds(pathname);
  const navItemsOpenedState = useState(
    navItems.reduce(
      (a, { id }) => ({ ...a, [id]: id === activePageId ? true : false }),
      {},
    ),
  );

  const { selectedVendor } = useVendorContext();
  const startPageUrl = selectedVendor ? `/${selectedVendor}/analytics` : "/";

  const { scopesWeHaveForVendor } = useSesamyAuthTokenContext();
  if (!scopesWeHaveForVendor) return null;

  const expandMenu = () => {
    const navStorage = getNavStorage();

    const toggledExpanded = !expanded;

    localStorage.setItem(
      "navStorage",
      JSON.stringify({
        ...navStorage,
        expanded: toggledExpanded,
      }),
    );

    setExpanded(toggledExpanded);
  };

  const filteredNavItems = navItems.filter(({ scopes }) =>
    userHasScopes(scopes, scopesWeHaveForVendor),
  );

  return (
    <aside className="sticky top-0 z-20 h-screen p-2 pr-4">
      <Column
        className={twMerge(
          "h-full w-[72px] gap-12 rounded-2xl bg-gray-800 p-4 transition-[width] duration-300",
          expanded && "w-[258px]",
        )}
        up
        left
      >
        <Clickable
          href={startPageUrl}
          className="w-full overflow-hidden p-1.5 row-left"
        >
          <div>
            <Icon className="text-2xl sm:text-2xl" name="sesamy-portal" />
          </div>
        </Clickable>
        <Column className="w-full gap-2" left>
          {filteredNavItems.map((navItem) => (
            <NavItem
              key={navItem.id}
              navItem={navItem}
              expanded={expanded}
              activePageId={activePageId}
              activeSubPageId={activeSubPageId}
              navItemsOpenedState={navItemsOpenedState}
            />
          ))}
        </Column>
      </Column>

      <Row
        className={twMerge(
          "absolute bottom-10 left-10 !flex-nowrap gap-1 whitespace-nowrap text-sm text-gray-300 transition-opacity duration-300 sm:text-sm",
          !expanded && "pointer-events-none opacity-0",
        )}
      >
        Need help?{" "}
        <Clickable
          className="text-white hover:cursor-pointer hover:underline"
          data-intercom-custom-launcher
        >
          Contact support
        </Clickable>
      </Row>

      <Clickable
        className="absolute bottom-12 right-2 h-8 w-8 rounded bg-gray-600 transition-colors duration-75 row hover:bg-gray-400"
        onClick={expandMenu}
      >
        <Icon
          className={twMerge(
            "translate-x-px text-xs text-gray-300 transition-transform duration-75 sm:text-xs",
            expanded && "-translate-x-px scale-x-[-1]",
          )}
          name="chevron-right"
        />
      </Clickable>
    </aside>
  );
};

import { ReactNode } from "react";
import SideNav from "@components/SideNav/SideNav";
import Head from "@components/Head";
import WithAuthPageWrapper from "@components/WithAuthPageWrapper";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import Row from "@components/Ui/Row";
import { twMerge } from "tailwind-merge";

export default ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();
  const { pathname } = useRouter();

  const isAllGraphsPage = pathname.endsWith("/all-graphs");
  const isVendorSelectPage = pathname.endsWith("/");
  const isPodcastPage = pathname.startsWith("/[vendor]/podcasts/");

  return user ? (
    <WithAuthPageWrapper>
      <Head />
      {isVendorSelectPage && children}
      {!isVendorSelectPage && (
        <div
          data-testid="main-scroll-wrapper"
          className="custom-scrollbar scrollbar-black h-full overflow-x-hidden overflow-y-scroll overscroll-none"
        >
          {isAllGraphsPage && children}
          {!isAllGraphsPage && (
            <Row
              className="w-full !flex-nowrap"
              data-testid="main-scroll-container"
              up
              left
            >
              <SideNav />
              <main
                className={twMerge(
                  "w-full gap-2 pt-0 column-up-left",
                  !isPodcastPage && "pb-2",
                )}
              >
                <div className="flex w-full min-w-0 flex-1 flex-col">
                  {children}
                </div>
              </main>
            </Row>
          )}
        </div>
      )}
    </WithAuthPageWrapper>
  ) : null;
};
